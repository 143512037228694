import { Component, OnInit, Renderer2 } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router, Scroll } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { filter } from 'rxjs';
import { TitleService } from 'src/app/shared/services/title.service';
import { DialogService } from 'src/app/shared/utils/dialog/dialog.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  updateAvailable = true;

  constructor(
    private dateAdapter: DateAdapter<Date>,
    private renderer: Renderer2,
    private router: Router,
    private title: Title,
    private titleService: TitleService,
    private swUpdate: SwUpdate,
    private dialogService: DialogService
  ) {
    if (this.swUpdate.isEnabled) {
      if (this.swUpdate.isEnabled) {
        this.swUpdate.versionUpdates.subscribe((event) => {
          if (event.type === 'VERSION_READY') {
            this.dialogService
              .openDialog({
                title: 'Update verfügbar',
                message:
                  'Ein Update ist verfügbar. Möchten Sie es jetzt installieren?',
              })
              .afterClosed()
              .subscribe((result) => {
                if (result) {
                  this.swUpdate
                    .activateUpdate()
                    .then(() => window.location.reload());
                }
              });
          }
        });
      }
    }
    this.dateAdapter.setLocale('de');
  }

  public ngOnInit() {
    this.addTrackingScript();
    this.router.events
      .pipe(
        filter(
          (event) =>
            event instanceof Scroll &&
            event.routerEvent instanceof NavigationEnd
        )
      )
      .subscribe((event) => {
        this.titleService.setBaseTitle(this.title.getTitle());
      });
  }

  addTrackingScript() {
    if (environment.umamiId) {
      const script = this.renderer.createElement('script');
      script.src = 'https://umi.wastics.eu/script.js';
      script.defer = true;
      script.setAttribute('data-website-id', environment.umamiId);
      this.renderer.appendChild(document.head, script);
    }
  }
}
