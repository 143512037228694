<ng-container *ngIf="element.aut">
  <span *ngIf="element.aut | wasteTitle | async as wasteTitle; else skeleton">
    {{ wasteTitle }}
  </span>
</ng-container>
<ng-container *ngIf="element.ewc"> {{ element.ewc }}</ng-container>
<ng-container *ngIf="!element.aut && $any(element).category">
  {{ $any(element).category }}
</ng-container>
<ng-container *ngIf="!element.aut && !$any(element).category && !element.ewc">
  Keine Daten angegeben
</ng-container>
<ng-template #skeleton>
  <mat-spinner diameter="20"></mat-spinner>
</ng-template>
