import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';

import { AuthGuard } from 'src/app/guards/auth.guard';
import { RegistrationGuard } from 'src/app/guards/registration.guard';
import { CompanyRoleFeatureGuard } from 'src/app/guards/company-role-feature.guard';
import { AUTH_MATRIX } from 'src/app/data/authMatrix';
import { VerifiedGuard } from 'src/app/guards/verified.guard';
import { LeavingGuard } from 'src/app/guards/leaving.guard';
import { environment } from 'src/environments/environment';

interface RouteData {
  roles?: string[];
}

interface RouteWithRole extends Route {
  data?: RouteData;
  path: keyof typeof AUTH_MATRIX;
}

export const routes: RouteWithRole[] = [
  {
    path: 'dashboard',
    canActivate: [RegistrationGuard],
    title: 'Dashboard • Wastics',
    loadComponent:
      environment.name !== 'PROD'
        ? () =>
            import('src/app/looker-demo/looker-demo.component').then(
              (x) => x.LookerDemoComponent
            )
        : () =>
            import('src/app/views/dashboard/dashboard.component').then(
              (x) => x.DashboardComponent
            ),
  },
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
    title: 'Wastics',
  },
  {
    path: 'dashboardv3',
    pathMatch: 'full',
    title: 'Wastics Demo Dashboard',
    loadComponent: () =>
      import('src/app/looker-demo/looker-demo.component').then(
        (x) => x.LookerDemoComponent
      ),
  },
  {
    path: 'welcome',
    title: 'Willkommen • Wastics',
    loadComponent: () =>
      import('src/app/views/intro/intro.component').then(
        (x) => x.IntroComponent
      ),
  },
  {
    path: 'agb',
    title: 'AGB',
    loadComponent: () =>
      import('src/app/views/wastics-agb/wastics-agb.component').then(
        (x) => x.WasticsAgbComponent
      ),
  },
  {
    path: 'legal',
    title: 'AGB / Impressum',
    loadComponent: () =>
      import('src/app/views/legal/legal.component').then(
        (x) => x.LegalComponent
      ),
  },
  {
    path: 'accept-agb',
    title: 'AGB',
    loadComponent: () =>
      import(
        'src/app/views/accept-wastics-agb/accept-wastics-agb.component'
      ).then((x) => x.AcceptWasticsAgbComponent),
  },
  {
    path: 'stammdaten',
    canActivate: [
      AuthGuard,
      VerifiedGuard,
      RegistrationGuard,
      CompanyRoleFeatureGuard,
    ],
    children: [
      {
        path: 'user-profile',
        title: 'Profile • Wastics',
        loadComponent: () =>
          import(
            'src/app/views/stammdaten/user-profile/user-profile.component'
          ).then((x) => x.UserProfileComponent),
      },
      {
        path: 'company-profile',
        title: 'Unternehmen',
        loadComponent: () =>
          import(
            'src/app/views/stammdaten/company-profile/company-profile.component'
          ).then((x) => x.CompanyProfileComponent),
      },
      {
        path: 'kontakt-einstellungen',
        loadComponent: () =>
          import(
            'src/app/views/contact-settings/contact-settings.component'
          ).then((x) => x.ContactSettingsComponent),
        title: 'Kontakt- und Benachrichtigungseinstellungen',
      },
      {
        path: 'standorte',
        children: [
          {
            path: '',
            title: 'Standorte • Wastics',
            loadComponent: () =>
              import(
                'src/app/views/locations/locations/locations.component'
              ).then((x) => x.LocationsComponent),
          },
          {
            path: 'form',
            title: 'Standorte • Wastics',
            loadComponent: () =>
              import(
                'src/app/views/locations/location-form/location-form.component'
              ).then((x) => x.LocationFormComponent),
          },
          {
            path: 'form/:id',
            title: 'Standorte • Wastics',
            loadComponent: () =>
              import(
                'src/app/views/locations/location-form/location-form.component'
              ).then((x) => x.LocationFormComponent),
          },
          {
            path: ':id',
            title: 'Standorte • Wastics',
            loadComponent: () =>
              import(
                'src/app/views/locations/location-details/location-details.component'
              ).then((x) => x.LocationDetailsComponent),
          },
        ],
      },
    ],
  },
  {
    path: 'awk',
    canActivate: [
      AuthGuard,
      VerifiedGuard,
      RegistrationGuard,
      CompanyRoleFeatureGuard,
    ],
    children: [
      {
        path: 'list',
        title: 'AWK Ubersicht • Wastics',
        loadComponent: () =>
          import('src/app/views/awk/awk-liste/awk-liste.component').then(
            (x) => x.AwkListeComponent
          ),
      },
      {
        path: 'list/:id',
        title: 'AWK Ubersicht • Wastics',
        loadComponent: () =>
          import('src/app/views/awk/awk-preview/awk-preview.component').then(
            (x) => x.AwkPreviewComponent
          ),
      },
      {
        path: 'neues-awk/:id',
        title: 'AWK Erstellen • Wastics',
        loadComponent: () =>
          import('src/app/views/awk/awk-wrapper/awk-wrapper.component').then(
            (x) => x.AwkWrapperComponent
          ),
        canDeactivate: [LeavingGuard],
      },
      {
        path: 'awk-entwurf/:id',
        title: 'AWK Erstellen • Wastics',
        loadComponent: () =>
          import('src/app/views/awk/awk-wrapper/awk-wrapper.component').then(
            (x) => x.AwkWrapperComponent
          ),
        canDeactivate: [LeavingGuard],
      },
      {
        path: 'neu',
        loadComponent: () =>
          import(
            'src/app/components/awk/prepare-new-awk/prepare-new-awk.component'
          ).then((x) => x.PrepareNewAwkComponent),
        canDeactivate: [LeavingGuard],
      },
      {
        path: 'awk-bearbeiten/:id',
        title: 'AWK Bearbeiten • Wastics',
        loadComponent: () =>
          import('src/app/views/awk/awk-wrapper/awk-wrapper.component').then(
            (x) => x.AwkWrapperComponent
          ),
        canDeactivate: [LeavingGuard],
      },
    ],
  },
  {
    path: 'auftrage',
    canActivate: [
      AuthGuard,
      VerifiedGuard,
      RegistrationGuard,
      CompanyRoleFeatureGuard,
    ],
    children: [
      {
        path: '',
        title: 'Meine Aufträge • Wastics',
        loadComponent: () =>
          import(
            'src/app/views/dwrm/orders/producer/orders/orders.component'
          ).then((x) => x.OrdersComponent),
      },
      {
        path: ':id',
        title: 'Meine Aufträge • Wastics',
        loadComponent: () =>
          import(
            'src/app/views/dwrm/orders/producer/order-details/order-details.component'
          ).then((x) => x.OrderDetailsComponent),
      },
    ],
  },
  {
    path: 'auftragsverwaltung',
    canActivate: [
      AuthGuard,
      VerifiedGuard,
      RegistrationGuard,
      CompanyRoleFeatureGuard,
    ],
    children: [
      {
        path: '',
        title: 'Auftragsverwaltung • Wastics',
        loadComponent: () =>
          import(
            'src/app/views/dwrm/orders/disposer/order-management-overview/order-management-overview.component'
          ).then((x) => x.OrderManagementOverviewComponent),
      },
      {
        path: ':id',
        title: 'Auftragsverwaltung • Wastics',
        loadComponent: () =>
          import(
            'src/app/views/dwrm/orders/disposer/order-management-details/order-management-details.component'
          ).then((x) => x.OrderManagementDetailsComponent),
      },
    ],
  },
  {
    path: 'aez/dokumente',
    canActivate: [
      AuthGuard,
      VerifiedGuard,
      RegistrationGuard,
      CompanyRoleFeatureGuard,
    ],
    children: [
      {
        path: '',
        title: 'Dokumente • Wastics',
        loadComponent: () =>
          import(
            'src/app/views/dwrm/documents/producer/documents-overview-producer/documents-overview-producer.component'
          ).then((x) => x.DocumentsOverviewProducerComponent),
      },
      {
        path: 'form',
        title: 'Dokumente • Wastics',
        loadComponent: () =>
          import(
            'src/app/views/dwrm/documents/producer/document-form-producer/document-form-producer.component'
          ).then((x) => x.DocumentFormProducerComponent),
      },
      {
        path: 'form/:id',
        title: 'Dokumente • Wastics',
        loadComponent: () =>
          import(
            'src/app/views/dwrm/documents/producer/document-form-producer/document-form-producer.component'
          ).then((x) => x.DocumentFormProducerComponent),
      },
      {
        path: ':id',
        title: 'Dokumente • Wastics',
        loadComponent: () =>
          import(
            'src/app/views/dwrm/documents/producer/document-details-producer/document-details-producer.component'
          ).then((x) => x.DocumentDetailsProducerComponent),
      },
    ],
  },
  {
    path: 'enb/dokumente',
    canActivate: [
      AuthGuard,
      VerifiedGuard,
      RegistrationGuard,
      CompanyRoleFeatureGuard,
    ],
    children: [
      {
        path: '',
        title: 'Dokumente • Wastics',
        loadComponent: () =>
          import(
            'src/app/views/dwrm/documents/disposer/documents-overview-disposer/documents-overview-disposer.component'
          ).then((x) => x.DocumentsOverviewDisposerComponent),
      },
      {
        path: 'form',
        title: 'Dokumente • Wastics',
        loadComponent: () =>
          import(
            'src/app/views/dwrm/documents/disposer/document-form-disposer/document-form-disposer.component'
          ).then((x) => x.DocumentFormDisposerComponent),
      },
      {
        path: 'form/:id',
        title: 'Dokumente • Wastics',
        loadComponent: () =>
          import(
            'src/app/views/dwrm/documents/disposer/document-form-disposer/document-form-disposer.component'
          ).then((x) => x.DocumentFormDisposerComponent),
      },
      {
        path: ':id',
        title: 'Dokumente • Wastics',
        loadComponent: () =>
          import(
            'src/app/views/dwrm/documents/disposer/document-details-disposer/document-details-disposer.component'
          ).then((x) => x.DocumentDetailsDisposerComponent),
      },
    ],
  },
  {
    path: 'neuer-entsorgungspartner',
    canActivate: [
      AuthGuard,
      VerifiedGuard,
      RegistrationGuard,
      CompanyRoleFeatureGuard,
    ],
    title: 'Neuer Entsorgungspartner',
    children: [
      {
        path: 'neu',
        title: 'Neuer Entsorgungspartner',
        loadComponent: () =>
          import(
            'src/app/components/matchmaking/prepeare-contract/prepeare-contract.component'
          ).then((x) => x.PrepeareContractComponent),
      },
      {
        path: 'partner/:inquiryElement/:id',
        title: 'Neuer Entsorgungspartner',
        loadComponent: () =>
          import(
            'src/app/views/new-disposal-partner/new-disposal-partner.component'
          ).then((x) => x.NewDisposalPartnerComponent),
      },
    ],
  },
  {
    path: 'awk-wrapper',
    loadComponent: () =>
      import('src/app/views/awk/awk-wrapper/awk-wrapper.component').then(
        (x) => x.AwkWrapperComponent
      ),
    canActivate: [
      AuthGuard,
      VerifiedGuard,
      RegistrationGuard,
      CompanyRoleFeatureGuard,
    ],
    title: 'Test • Awk-Wrapper',
  },
  {
    path: 'neue-entsorgungsanfrage',
    canActivate: [
      AuthGuard,
      VerifiedGuard,
      RegistrationGuard,
      CompanyRoleFeatureGuard,
    ],
    children: [
      {
        path: '',
        pathMatch: 'full',
        loadComponent: () =>
          import(
            'src/app/views/choose-new-or-existing-inquiry/choose-new-or-existing-inquiry.component'
          ).then((x) => x.ChooseNewOrExistingInquiryComponent),
        title: 'Neue Entsorgungsanfrage',
      },
      {
        path: 'neu',
        loadComponent: () =>
          import(
            'src/app/components/matchmaking/prepare-new-inquiry/prepare-new-inquiry.component'
          ).then((x) => x.PrepareNewInquiryComponent),
        title: 'Neue Entsorgungsanfrage',
      },
      {
        path: 'neu/:inquiryId',
        loadComponent: () =>
          import(
            'src/app/components/matchmaking/prepare-new-inquiry/prepare-new-inquiry.component'
          ).then((x) => x.PrepareNewInquiryComponent),
        title: 'Neue Entsorgungsanfrage',
      },
      {
        path: ':inquiryElement/:step',
        loadComponent: () =>
          import('src/app/views/create-inquiry/create-inquiry.component').then(
            (x) => x.CreateInquiryComponent
          ),
        title: 'Neue Entsorgungsanfrage',
      },
      {
        path: ':inquiryElement/:step/:inquiryId',
        loadComponent: () =>
          import('src/app/views/create-inquiry/create-inquiry.component').then(
            (x) => x.CreateInquiryComponent
          ),
        title: 'Entsorgungsanfrage bearbeiten',
      },
    ],
  },
  ,
  {
    path: 'erzeuger-anfragen',
    canActivate: [
      AuthGuard,
      VerifiedGuard,
      RegistrationGuard,
      CompanyRoleFeatureGuard,
    ],
    children: [
      {
        path: '',
        title: 'Erzeuger-Anfragen',
        loadComponent: () =>
          import(
            'src/app/views/producer-inquiries/producer-inquiries.component'
          ).then((x) => x.ProducerInquiriesComponent),
      },
      {
        path: 'angebote',
        title: 'Erzeuger-Anfragen',
        data: { onlyShowInquiriesWithOffers: true },
        loadComponent: () =>
          import(
            'src/app/views/producer-inquiries/producer-inquiries.component'
          ).then((x) => x.ProducerInquiriesComponent),
      },
      {
        path: 'entwuerfe',
        title: 'Erzeuger-Anfragen',
        data: { status: ['DRAFT'] },
        loadComponent: () =>
          import(
            'src/app/views/producer-inquiries/producer-inquiries.component'
          ).then((x) => x.ProducerInquiriesComponent),
      },
      {
        path: ':inquiryId',
        title: 'Erzeuger-Anfrage',
        loadComponent: () =>
          import(
            'src/app/views/producer-inquiry-details/producer-inquiry-details.component'
          ).then((x) => x.ProducerInquiryDetailsComponent),
      },
    ],
  },
  {
    path: 'entsorger-anfragen',
    canActivate: [
      AuthGuard,
      VerifiedGuard,
      RegistrationGuard,
      CompanyRoleFeatureGuard,
    ],
    children: [
      {
        path: '',
        title: 'Entsorger-Anfragen',
        loadComponent: () =>
          import(
            'src/app/views/disposer-inquiries/disposer-inquiries.component'
          ).then((x) => x.DisposerInquiriesComponent),
      },
      {
        path: ':inquiryId',
        title: 'Anfrage',
        loadComponent: () =>
          import(
            'src/app/views/disposer-inquiry-details/disposer-inquiry-details.component'
          ).then((x) => x.DisposerInquiryDetailsComponent),
      },
    ],
  },
  {
    path: 'entsorger-angebote',
    canActivate: [
      AuthGuard,
      VerifiedGuard,
      RegistrationGuard,
      CompanyRoleFeatureGuard,
    ],
    children: [
      {
        path: '',
        loadComponent: () =>
          import(
            'src/app/components/matchmaking/disposer-offers/disposer-offers.component'
          ).then((x) => x.DisposerOffersComponent),
      },
      {
        path: ':offerId',
        loadComponent: () =>
          import(
            'src/app/components/matchmaking/disposer-offer-details/disposer-offer-details.component'
          ).then((x) => x.DisposerOfferDetailsComponent),
      },
    ],
  },
  {
    path: 'neues-angebot',
    canActivate: [
      AuthGuard,
      VerifiedGuard,
      RegistrationGuard,
      CompanyRoleFeatureGuard,
    ],
    children: [
      {
        path: 'abfaelle/:matchId',
        title: 'Abfälle auswählen',
        loadComponent: () =>
          import(
            'src/app/views/disposer-inquiry-selection/disposer-inquiry-selection.component'
          ).then((x) => x.DisposerInquirySelectionComponent),
      },
      {
        path: ':matchId/:step',
        title: 'Neues Angebot erstellen',
        canDeactivate: [LeavingGuard],
        loadComponent: () =>
          import('src/app/views/new-offer/new-offer.component').then(
            (x) => x.NewOfferComponent
          ),
      },
    ],
  },
  {
    path: 'erzeuger-angebote',
    canActivate: [
      AuthGuard,
      VerifiedGuard,
      RegistrationGuard,
      CompanyRoleFeatureGuard,
    ],
    children: [
      {
        path: ':inquiryId',
        loadComponent: () =>
          import(
            'src/app/views/producer-offers/producer-offers.component'
          ).then((x) => x.ProducerOffersComponent),
        title: 'Meine Angebote',
      },
      {
        path: 'angebot/:offerId',
        loadComponent: () =>
          import(
            'src/app/views/producer-offer-details/producer-offer-details.component'
          ).then((x) => x.ProducerOfferDetailsComponent),
        title: 'Angebot',
      },
      {
        path: 'vergleich/:offerIds',
        title: 'Angebot-Details',
        loadComponent: () =>
          import('src/app/views/offer-compare/offer-compare.component').then(
            (x) => x.OfferCompareComponent
          ),
      },
    ],
  },
  {
    path: 'vertraegeDetails/:id',
    canActivate: [
      AuthGuard,
      VerifiedGuard,
      RegistrationGuard,
      CompanyRoleFeatureGuard,
    ],
    loadComponent: () =>
      import(
        'src/app/components/matchmaking/contract-detail-view/contract-detail-view.component'
      ).then((x) => x.ContractDetailViewComponent),
    title: 'Verträge',
  },
  {
    path: 'vertraegeDetails/pending/:id',
    canActivate: [
      AuthGuard,
      VerifiedGuard,
      RegistrationGuard,
      CompanyRoleFeatureGuard,
    ],
    loadComponent: () =>
      import(
        'src/app/components/matchmaking/contract-pending-detail-view/contract-pending-detail-view.component'
      ).then((x) => x.ContractPendingDetailViewComponent),
    title: 'Verträge',
  },
  {
    path: 'vertraege/entwuerfe',
    canActivate: [
      AuthGuard,
      VerifiedGuard,
      RegistrationGuard,
      CompanyRoleFeatureGuard,
    ],
    loadComponent: () =>
      import(
        'src/app/components/matchmaking/contract-draft-list/contract-draft-list.component'
      ).then((x) => x.ContractDraftListComponent),
    title: 'Verträge',
  },
  {
    path: 'erzeuger-vertraege',
    canActivate: [
      AuthGuard,
      VerifiedGuard,
      RegistrationGuard,
      CompanyRoleFeatureGuard,
    ],
    loadComponent: () =>
      import(
        'src/app/views/contracts-producer/contracts-producer.component'
      ).then((x) => x.ContractsProducerComponent),
    title: 'Verträge',
  },
  {
    path: 'erzeuger-ausstehende-vertraege',
    canActivate: [
      AuthGuard,
      VerifiedGuard,
      RegistrationGuard,
      CompanyRoleFeatureGuard,
    ],
    loadComponent: () =>
      import(
        'src/app/views/contract-pending-producer/contract-pending-producer.component'
      ).then((x) => x.ContractPendingProducerComponent),
    title: 'Verträge',
  },
  {
    path: 'entsorger-vertraege',
    canActivate: [
      AuthGuard,
      VerifiedGuard,
      RegistrationGuard,
      CompanyRoleFeatureGuard,
    ],
    children: [
      {
        path: '',
        loadComponent: () =>
          import(
            'src/app/views/contracts-disposal/contracts-disposal.component'
          ).then((x) => x.ContractsDisposalComponent),
        title: 'Verträge',
      },
      {
        path: 'upload',
        loadComponent: () =>
          import(
            'src/app/views/contract-document-upload/contract-document-upload.component'
          ).then((x) => x.ContractDocumentUploadComponent),
        title: 'Dokument hochladen',
      },
    ],
  },
  {
    path: 'entsorger-ausstehende-vertraege',
    canActivate: [
      AuthGuard,
      VerifiedGuard,
      RegistrationGuard,
      CompanyRoleFeatureGuard,
    ],
    loadComponent: () =>
      import(
        'src/app/views/contract-pending-disposer/contract-pending-disposer.component'
      ).then((x) => x.ContractPendingDisposerComponent),
    title: 'Verträge',
  },
  {
    path: 'admin',
    canActivate: [AuthGuard],
    data: {
      roles: ['wastics_admin'],
    },
    children: [
      {
        path: 'documents',
        children: [
          {
            path: '',
            loadComponent: () =>
              import(
                'src/app/views/dwrm/documents/admin/document-overview-admin/document-overview-admin.component'
              ).then((x) => x.DocumentOverviewAdminComponent),
            title: 'Documents',
          },
        ],
      },
      {
        path: 'contracts',
        children: [
          {
            path: '',
            loadComponent: () =>
              import('src/app/views/admin/contracts/contracts.component').then(
                (x) => x.ContractsComponent
              ),
            title: 'Contracts',
          },
        ],
      },
      {
        path: 'firmen',
        children: [
          {
            path: '',
            loadComponent: () =>
              import('src/app/views/admin/companies/companies.component').then(
                (x) => x.CompaniesComponent
              ),
            title: 'Firmenverwaltung',
          },
          {
            path: ':companyId',
            title: 'Firmen-Details',
            loadComponent: () =>
              import(
                'src/app/views/admin/company-details/company-details.component'
              ).then((x) => x.CompanyDetailsComponent),
          },
        ],
      },
      {
        path: 'anfragen',
        children: [
          {
            path: '',
            loadComponent: () =>
              import('src/app/views/admin/inquiries/inquiries.component').then(
                (x) => x.InquiriesComponent
              ),
            title: 'Anfragen',
          },
          {
            path: ':inquiryId',
            title: 'Anfrage-Details',
            loadComponent: () =>
              import(
                'src/app/views/admin/inquiry-details/inquiry-details.component'
              ).then((x) => x.InquiryDetailsComponent),
          },
          {
            path: ':inquiryId/angebote',
            loadComponent: () =>
              import('src/app/views/admin/offers/offers.component').then(
                (x) => x.OffersComponent
              ),
            title: 'Angebote',
          },
        ],
      },
      {
        path: 'anfragen-info',
        children: [
          {
            path: '',
            loadComponent: () =>
              import(
                '../views/admin/inquiries-info/inquiries-info.component'
              ).then((x) => x.InquiriesInfoComponent),
            title: 'Anfragen',
          },
          {
            path: ':inquiryId',
            title: 'Anfrage-Details',
            loadComponent: () =>
              import(
                '../views/admin/inquiry-details/inquiry-details.component'
              ).then((x) => x.InquiryDetailsComponent),
          },
          {
            path: ':inquiryId/angebote',
            loadComponent: () =>
              import('../views/admin/offers/offers.component').then(
                (x) => x.OffersComponent
              ),
            title: 'Angebote',
          },
        ],
      },
      {
        path: 'angebote',
        children: [
          {
            path: ':offerId',
            title: 'Angebot-Details',
            loadComponent: () =>
              import(
                'src/app/views/admin/offer-details/offer-details.component'
              ).then((x) => x.OfferDetailsComponent),
          },
        ],
      },
      {
        path: 'benutzer',
        title: 'Benutzer',
        loadComponent: () =>
          import('src/app/views/admin/users/users.component').then(
            (x) => x.UsersComponent
          ),
      },
      {
        path: 'vertragsbedingungen',
        children: [
          {
            path: '',
            title: 'Vertragsbedingungen',
            loadComponent: () =>
              import(
                'src/app/views/admin/contract-terms/contract-terms.component'
              ).then((x) => x.ContractTermsComponent),
          },
          {
            path: ':contractTermsId',
            title: 'Vertragsbedingungen',
            loadComponent: () =>
              import(
                'src/app/views/admin/contract-terms-detail/contract-terms-detail.component'
              ).then((x) => x.ContractTermsDetailComponent),
          },
        ],
      },
      {
        path: 'matches',
        title: 'Matches',
        loadComponent: () =>
          import('src/app/views/admin/matches/matches.component').then(
            (x) => x.MatchesComponent
          ),
        children: [
          {
            path: ':inquiryId',
            title: 'Match Details',
            loadComponent: () =>
              import(
                'src/app/views/admin/match-detail/match-detail.component'
              ).then((x) => x.MatchDetailComponent),
          },
        ],
      },
      {
        path: 'match-mail/:inquiryId',
        title: 'Matches per Mail anschreiben',
        loadComponent: () =>
          import('src/app/views/admin/match-mail/match-mail.component').then(
            (x) => x.MatchMailComponent
          ),
      },
      {
        path: 'neues-unternehmen',
        title: 'Unternehmen bearbeiten',
        loadComponent: () =>
          import(
            'src/app/views/admin/edit-company/edit-company.component'
          ).then((x) => x.EditCompanyComponent),
      },
      {
        path: 'unternehmen-bearbeiten/:companyId',
        title: 'Unternehmen bearbeiten',
        loadComponent: () =>
          import(
            'src/app/views/admin/edit-company/edit-company.component'
          ).then((x) => x.EditCompanyComponent),
      },
      {
        path: 'zertifikate',
        loadComponent: () =>
          import(
            'src/app/views/admin/certificates/certificates.component'
          ).then((x) => x.CertificatesComponent),
        title: 'Zertifikate',
      },
      {
        path: 'zertifikate/:certificateId',
        loadComponent: () =>
          import(
            'src/app/views/admin/edit-certificate/edit-certificate.component'
          ).then((x) => x.EditCertificateComponent),
        title: 'Zertifikat bearbeiten',
      },
    ],
  },
  {
    path: 'kundenschutzvereinbarung',
    title: 'Kundenschutzvereinbarung',
    canActivate: [
      AuthGuard,
      VerifiedGuard,
      RegistrationGuard,
      CompanyRoleFeatureGuard,
    ],
    loadComponent: () =>
      import('src/app/views/wastics-terms/wastics-terms.component').then(
        (x) => x.WasticsTermsComponent
      ),
  },
  {
    path: 'vertragsbedingungen/unternehmen/:companyId',
    canActivate: [
      AuthGuard,
      VerifiedGuard,
      RegistrationGuard,
      CompanyRoleFeatureGuard,
    ],
    title: 'Vertragsbedingungen',
    loadComponent: () =>
      import('src/app/views/contract-terms/contract-terms.component').then(
        (x) => x.ContractTermsComponent
      ),
  },
  {
    path: 'betrieb',
    canActivate: [AuthGuard, VerifiedGuard, RegistrationGuard],
    children: [
      {
        path: 'vertragsbedingungen',
        title: 'Vertragsbedingungen',
        canActivate: [CompanyRoleFeatureGuard],
        loadComponent: () =>
          import(
            'src/app/views/edit-contract-terms/edit-contract-terms.component'
          ).then((x) => x.EditContractTermsComponent),
      },
      {
        path: 'anlagen',
        canActivate: [CompanyRoleFeatureGuard],
        children: [
          {
            path: '',
            title: 'Anlagen',
            loadComponent: () =>
              import('src/app/views/facilities/facilities.component').then(
                (x) => x.FacilitiesComponent
              ),
          },
          {
            path: 'neu',
            title: 'Neue Anlage',
            loadComponent: () =>
              import(
                'src/app/views/edit-facility/edit-facility.component'
              ).then((x) => x.EditFacilityComponent),
          },
          {
            path: ':facilityId',
            title: 'Anlage bearbeiten',
            loadComponent: () =>
              import(
                'src/app/views/edit-facility/edit-facility.component'
              ).then((x) => x.EditFacilityComponent),
          },
        ],
      },
      {
        path: 'stoffstroeme',
        children: [
          {
            path: '',
            title: 'Stoffströme',

            loadComponent: () =>
              import(
                'src/app/views/waste-streams/waste-streams.component'
              ).then((x) => x.WasteStreamsComponent),
          },
          {
            path: 'neu',
            title: 'Neuer Stoffstrom',
            loadComponent: () =>
              import(
                'src/app/views/edit-waste-stream/edit-waste-stream.component'
              ).then((x) => x.EditWasteStreamComponent),
          },
          {
            path: ':wasteStreamId',
            title: 'Stoffstrom bearbeiten',
            loadComponent: () =>
              import(
                'src/app/views/edit-waste-stream/edit-waste-stream.component'
              ).then((x) => x.EditWasteStreamComponent),
          },
        ],
      },
      {
        path: 'zertifikate',
        canActivate: [CompanyRoleFeatureGuard],
        children: [
          {
            path: '',
            loadComponent: () =>
              import('src/app/views/certificates/certificates.component').then(
                (x) => x.CertificatesComponent
              ),
            title: 'Zertifikate',
          },
          {
            path: 'neu',
            loadComponent: () =>
              import(
                'src/app/views/new-certificate/new-certificate.component'
              ).then((x) => x.NewCertificateComponent),
            title: 'Neues Zertifikat',
          },
        ],
      },
      {
        path: 'entsorgungspartner',
        children: [
          {
            path: '',
            title: 'Entsorgungspartner • Wastics',
            loadComponent: () =>
              import(
                'src/app/views/betrieb/disposal-partners/disposal-partners.component'
              ).then((x) => x.DisposalPartnersComponent),
          },
          {
            path: ':id',
            title: 'Entsorgungspartner • Wastics',
            loadComponent: () =>
              import(
                'src/app/views/betrieb/disposal-partners-details/disposal-partners-details.component'
              ).then((x) => x.DisposalPartnersDetailsComponent),
          },
        ],
      },
      {
        path: 'sammelstellen',
        children: [
          {
            path: '',
            title: 'Sammelstellen • Wastics',
            loadComponent: () =>
              import(
                'src/app/views/betrieb/collection-points-overview/collection-points-overview.component'
              ).then((x) => x.CollectionPointsOverviewComponent),
          },
        ],
      },
      {
        path: 'abfallarten',
        title: 'Abfallarten • Wastics',
        children: [
          {
            path: '',
            loadComponent: () =>
              import(
                'src/app/views/dwrm/orders/producer/waste-types/waste-types.component'
              ).then((x) => x.WasteTypesComponent),
          },
          {
            path: ':id',
            loadComponent: () =>
              import(
                'src/app/views/dwrm/orders/producer/waste-type-details/waste-type-details.component'
              ).then((x) => x.WasteTypeDetailsComponent),
          },
        ],
      },
      {
        path: 'entsorgungspartner',
        children: [
          {
            path: '',
            title: 'Entsorgungspartner • Wastics',
            loadComponent: () =>
              import(
                'src/app/views/betrieb/disposal-partners/disposal-partners.component'
              ).then((x) => x.DisposalPartnersComponent),
          },
          {
            path: ':id',
            title: 'Entsorgungspartner • Wastics',
            loadComponent: () =>
              import(
                'src/app/views/betrieb/disposal-partners-details/disposal-partners-details.component'
              ).then((x) => x.DisposalPartnersDetailsComponent),
          },
        ],
      },
    ],
  },
  {
    path: 'abfallstrome',
    canActivate: [
      AuthGuard,
      VerifiedGuard,
      RegistrationGuard,
      CompanyRoleFeatureGuard,
    ],
    children: [
      {
        path: '',
        title: 'Abfallströme • Wastics',
        loadComponent: () =>
          import(
            'src/app/views/dwrm/orders/producer/waste-streams/waste-streams.component'
          ).then((x) => x.WasteStreamsComponent),
      },
      {
        path: ':id',
        title: 'Abfallströme • Wastics',
        loadComponent: () =>
          import(
            'src/app/views/dwrm/orders/producer/waste-stream-details/waste-stream-details.component'
          ).then((x) => x.WasteStreamDetailsComponent),
      },
    ],
  },
  {
    path: 'dashboardv2',
    title: 'Dashboard / v2',
    loadComponent: () =>
      import('../views/dashboard-v2/dashboard-v2.component').then(
        (x) => x.DashboardV2Component
      ),
  },
  {
    path: '**',
    redirectTo: 'dashboard',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
