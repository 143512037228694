import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from 'src/app/components/shared/confirmation-dialog/confirmation-dialog.component';

interface ConfirmationDialogData {
  title: string;
  message: string;
  confirmButton?: string;
  cancelButton?: string;
  confirmColor?: 'primary' | 'accent' | 'warn' | undefined;
  cancelColor?: 'primary' | 'accent' | 'warn' | undefined;
  onConfirm?: () => void;
  onCancel?: () => void;
}

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  constructor(private _dialog: MatDialog) {}

  openDialog({
    title,
    message,
    confirmButton = 'Bestätigen',
    cancelButton = 'Abbrechen',
    confirmColor = 'primary',
    cancelColor,
  }: ConfirmationDialogData) {
    return this._dialog.open(ConfirmationDialogComponent, {
      data: {
        title,
        message,
        confirmButton,
        cancelButton,
        confirmColor,
        cancelColor,
      },
    });
  }
}
