<h1 mat-dialog-title>{{ data.title }}</h1>
<div mat-dialog-content>{{ data.message }}</div>
<div mat-dialog-actions align="end">
  <button
    *ngIf="data.cancelButton"
    mat-button
    [color]="data.cancelColor"
    (click)="handleCancel()"
  >
    {{ data.cancelButton }}
  </button>
  <button
    mat-button
    cdkFocusInitial
    [color]="data.confirmColor"
    (click)="handleConfirm()"
  >
    {{ data.confirmButton }}
  </button>
</div>
